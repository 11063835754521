import Layout from "../components/layout/layout";
import { graphql } from "gatsby";
import React from "react";
import Parser from "../components/parser.js";

const Index = ({ data }) => {
  return (
    <Layout
      infos={{
        title: data.wpPage.title,
        postType: "index"
      }}
      seo={
        data.wpPage.seo
      }
    >
      <Parser content={ data.wpPage.content } />
    </Layout>
  );
};
export const pageQuery = graphql`
  query FrontPage {
    wpPage(isFrontPage: {eq: true}) {
      content
      title
      seo {
        canonicalUrl
        description
        openGraphDescription
        openGraphTitle
        openGraphType
        title
        twitterDescription
        twitterTitle
        noIndex
        excludeLocalSearch
        excludeFromArchive
        noArchive
        noFollow
        redirectUrl
        socialImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
export default Index;
